export default {
  rivista: (payload) => {
    return `
      {
        data: search(
          index: "dpp_portale"
          query: {
            text: ""
            filter: {
              bool: {
                should: []
                must_not: []
                must: [
                  {
                    range: {
                      arg: { name: "provvedimento.data", value: { lte: "now/d" } }
                    }
                  }
                  { term: { arg: { name: "provvedimento.idDatabanks", value: { value: "${payload.idDatabanks}" } } } }
                  { term: { arg: { name: "provvedimento.tipologiaDoc", value: { value: "${payload.tipologiaDoc}" } } } }
                ]
              }
            }
            fields: [
              "testo"
              "provvedimento.titolo^10"
              "documento.campiCalcolati.estremo^10"
              "provvedimento.campiCalcolati.estremo^10"
            ]
          }
          source: [
            "provvedimento.*"
            "documento.*"
            "abstracts.*"
            "first"
            "autori.*"
            "estremiAutori.*"
            "metadatiJson.*"
            "settoreCategoria.*"
            "metadataMultimedia.*"
          ]
          from: ${payload.from}
          size: ${payload.size}
          sort: [{ arg: { name: "provvedimento.data", value: "desc" } }]
          track_total_hits: true
          opt: {}
        ) {
          aggregations
          hits {
            total
            hits {
              _source
            }
          }
        }
      }`
  },
  getDocumentAuth: (payload) => {
    return `
      query {
        getDocumentAuth(
          idDocMaster: ${payload.idm}
          text: true
          clearLink: false
          packets: true
          author: true
          rn: true
          rnPackets: false
          free: { active: true, abstract: [156] }
        )
      }`
  },
  getLinksByHref: (payload) => {
    const value = `
      {
        getLinksByHref
        (
          href: "${payload.href}"
          idDocMaster: ${payload.idDocMaster}
          idUnitaDoc: ${payload.idunitadoc}
        )
      {
        iddocmasterj
        idunitadocj 
        iddatabanksj
        nvigunitadocj
        tipologiaj
        titoloj
      }
    }`
    return value
  }
}
