import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import NotFoundComponent from '../views/NotFound.vue'
var qs = require('qs');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Biblioteca Riviste',
      metaTags: [
        {
          name: 'description',
          content: 'The home page'
        }
      ]
    }
  },
  {
    path: '/riviste',
    name: "Riviste",
    component: () => import('../views/Magazines.vue'),
    meta: {
      title: 'Biblioteca Riviste',
      metaTags: [
        {
          name: 'description',
          content: 'My magazines'
        }
      ]
    }
  },
  {
    path: '/rivista/:cod_riv',
    name: "Rivista",
    component: () => import('../views/MagazineDetail.vue'),
    meta: {
      title: 'Biblioteca Riviste',
      metaTags: [
        {
          name: 'description',
          content: 'My magazine detail'
        }
      ]
    }
  },
  {
    path: '/news/:cod_riv/:tipologiaDoc/:idm',
    name: "News",
    component: () => import('../views/NewsList.vue'),
    meta: {
      title: 'Biblioteca Riviste'
    }
  },
  {
    path: '/catalogo',
    component: () => import('../views/Catalogue.vue'),
    meta: {
      title: 'Biblioteca Riviste',
      metaTags: [
        {
          name: 'description',
          content: 'Catalogue'
        }
      ]
    }
  },
  {
    path: '/results',
    component: () => import('../views/Results.vue'),
    meta: {
      title: 'Biblioteca Riviste',
      metaTags: [
        {
          name: 'description',
          content: 'Results'
        }
      ]
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/Details.vue'),
    meta: {
      title: 'Biblioteca Riviste',
      metaTags: [
        {
          name: 'description',
          content: 'Details'
        }
      ]
    }
  },
  {
    path: '/cookie',
    component: () => import('../views/Cookie.vue'),
    meta: {
      title: 'Biblioteca Riviste',
      metaTags: [
        {
          name: 'description',
          content: 'Cookie'
        }
      ]
    }
  },
  {
    path: '*',
    component: NotFoundComponent
  }
];

const router = new VueRouter({
  stringifyQuery  : query => {
    let result = qs.stringify(query, { encode: false })
    return result ? ('?' + result) : ''
  },
  routes
});

export default router;
