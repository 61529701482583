import { GraphQLClient } from "graphql-request";
import queryBook from "@/services/queries";

const client = (url, token) =>
  new GraphQLClient(url, {
    headers: { Authorization: token },
  });

export default {
  rivista(url, jwt, data) {
    let payload = queryBook.rivista(data);
    return client(url, jwt).request(payload);
  },
  getDocumentAuth(data) {
    let payload = queryBook.getDocumentAuth(data);
    return client(data.url, data.jwt).request(payload);
  },
  getLinksByHref(data) {
    let payload = queryBook.getLinksByHref(data.payload);
    return client(data.url, data.jwt).request(payload);
  },
};
