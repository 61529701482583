<template>
  <div v-if="!overlay">
    <div v-if="catalogue">
      <!-- COVER contiene il box introduttivo che può contenere un messaggio promo o i campi di ricerca dell'Homepage -->
      <div class="maxlayout container container__margin">
        <div class="cover bg-grey border-red">
          <div class="cover__caption">
            <div class="cover__caption__copy">
              <div class="cover__caption__copy__searchbox">
                <div class="azioni-top-right-azp">
                  <div class="azioni-title-main-azp">
                    <div class="azioni-title-azp-ricerca">
                      <h1>Cerca in tutto il catalogo GFL</h1>
                    </div>
                    <div class="search-box-in-rip clearfix">
                      <div class="search-small-icon-hp">
                        <img src="images/ico_search.svg" alt="" />
                      </div>
                      <div class="search-small-right-hp">
                        <div class="search-small-input-hp">
                          <v-combobox
                            class="comboMateriaHome"
                            v-model="materiaSelected"
                            :items="materie"
                            light
                            item-text="descrizione"
                            :menu-props="propcombo"
                            placeholder="Materia"
                            dense
                            clearable
                            filled
                            @change="setMateria()"
                          ></v-combobox>
                          <v-text-field
                            light
                            placeholder="Cerca per parole"
                            v-on:keyup="keyPress"
                            v-model="searchText"
                            filled
                            dense
                          ></v-text-field>

                          <div class="search-small-main-btn-hp">
                            <input
                              type="submit"
                              @click="research()"
                              value="cerca"
                              class="cta red"
                            />
                            <div class="advance-hp">
                              <a href="#" class="lnk blue">Ricerca avanzata</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  v-if="settings"
                  :href="settings.marketing.url_form_agenti"
                  target="_blank"
                  class="cta red small"
                  title="Richiedi maggiori informazioni"
                  >Richiedi maggiori informazioni</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="section border-red" id="grid__biblioteca" v-if="catalogue">
          <div class="intro">
            <h2>Biblioteca</h2>
            <h4>Ultime uscite.</h4>
          </div>
          <div class="hidden-sm-and-down">
            <!-- GRID box per gestire la visualizzazione a griglia dei volumi -->
            <div class="grid clearfix">
              <div
                class="grid__item"
                v-for="item in catalogue.es.list"
                :key="item"
                @click="detail(item)"
              >
                <a class="grid__item__caption">
                  <div class="grid__item__caption__image">
                    <v-img
                      v-if="item._source.provvedimento.campiCalcolati.cover_url"
                      width="217"
                      v-bind:src="fixCopertine(
                        settings.copertina +
                        '/pdf/' +
                        item._source.provvedimento.idDocMaster
                          .toString()
                          .substring(
                            item._source.provvedimento.idDocMaster.toString()
                              .length - 3,
                            item._source.provvedimento.idDocMaster.toString()
                              .length
                          ) +
                        item._source.provvedimento.campiCalcolati.cover_url +
                        '?width=217&fit=outside')
                      "
                      :aspect-ratio="0.629"
                    ></v-img>

                    <v-img
                      v-else
                      width="217"
                      :src="fixCopertine(item._source.provvedimento.campiCalcolati.rivista_cover_url)"
                    ></v-img>
                  </div>
                  <div class="grid__item__caption__text">
                    <h4>{{ item._source.provvedimento.eTitolo }}</h4>
                    <p>
                      <span v-if="item._source.provvedimento.collana">{{
                        item._source.provvedimento.collana.descrizione
                      }}</span>
                      <br />
                      <span
                        style="font-weight: bold; display: block"
                        v-if="false && item._source.autori"
                        >{{ item._source.autori[0].nome.substring(0, 1) }}.
                        {{ item._source.autori[0].cognome }}</span
                      >
                      <span
                        style="font-weight: bold"
                        v-if="
                          false && item._source.autori && item._source.autori[1]
                        "
                        >{{ item._source.autori[1].nome.substring(0, 1) }}.
                        {{ item._source.autori[1].cognome }}</span
                      >
                    </p>
                  </div>
                  <div class="metadata-row" style="margin-left:15px; margin-top:-15px">
                      <span class="metadata">Anno:</span>
                      <span class="metadata__value" style="color:black">{{
                        item._source.provvedimento.annoRiv
                      }}</span>
                    </div>
                    <div class="metadata-row" style="margin-left:15px">
                      <span class="metadata">Fascicolo:</span>
                      <span class="metadata__value" style="color:black"
                        >{{ item._source.provvedimento.fascicolo1 }} 
                        {{ item._source.provvedimento.fascicolo2 ? " - " + item._source.provvedimento.fascicolo2 : "" }}</span
                      >
                    </div>
                </a>
              </div>
            </div>

            <!-- in versione smartphone invece del div GRID verrà mostrato il div con il carosello immagini -->
            <!--<div class="carousel">
              <div class="corousel__pagination-bullets"></div>
          </div>-->
          </div>

          <v-card
            elevation="24"
            max-width="444"
            class="mx-auto hidden-md-and-up"
            style="width: 217px; !important"
          >
            <v-carousel
              v-model="model"
              hide-delimiters
              @change="carousel(item)"
              class="carousel"
              v-if="catalogue"
            >
              <v-carousel-item
                v-for="item in catalogue.es.list"
                :key="item"
                @click="detail(item)"
                class="corousel__pagination-number"
              >
                <v-img
                  v-if="item._source.provvedimento.campiCalcolati.cover_url"
                  width="217"
                  contain
                  :src="fixCopertine(
                    settings.copertina +
                    '/pdf/' +
                    item._source.provvedimento.idDocMaster
                      .toString()
                      .substring(
                        item._source.provvedimento.idDocMaster.toString()
                          .length - 3,
                        item._source.provvedimento.idDocMaster.toString().length
                      ) +
                    item._source.provvedimento.campiCalcolati.cover_url +
                    '?width=217&fit=outside')
                  "
                  :aspect-ratio="0.629"
                ></v-img>

                <v-img
                  v-else
                  width="217"
                  :src="fixCopertine(item._source.provvedimento.campiCalcolati.rivista_cover_url)"
                ></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
          <v-row
            justify="space-around"
            style="margin-top: 20px"
            class="mx-auto hidden-md-and-up"
          >
            <div class="grid__item__caption__text">
              <h4>{{ currentItem._source.provvedimento.eTitolo }}</h4>
              <p>
                <span v-if="currentItem._source.provvedimento.collana">{{
                  currentItem._source.provvedimento.collana.descrizione
                }}</span>
                <br />
                <span
                  style="font-weight: bold"
                  v-if="currentItem._source.autori && false"
                  >{{ currentItem._source.autori[0].nome.substring(0, 1) }}.
                  {{ currentItem._source.autori[0].cognome }}
                </span>
                <span
                  style="font-weight: bold"
                  v-if="
                    false &&
                    currentItem._source.autori &&
                    currentItem._source.autori[1]
                  "
                  >{{ currentItem._source.autori[1].nome.substring(0, 1) }}.
                  {{ currentItem._source.autori[1].cognome }}</span
                >
              </p>
            </div>
          </v-row>

          <div class="banner__promo">
            <div class="row">
              <div class="col-sm-6 col-12">
                <!-- Revive Adserver Hosted edition - Ad Management Asynchronous JS Tag - Generated with Revive Adserver v5.0.5 -->
               <ins type="text/plain" class="optanon-category-C0004"
                data-revive-zoneid="7163"
                data-revive-id="727bec5e09208690b050ccfc6a45d384"
              ></ins>
              </div>
              <div class="col-sm-6 col-12">
                <ins type="text/plain" class="optanon-category-C0004"
                  data-revive-zoneid="7164"
                  data-revive-id="727bec5e09208690b050ccfc6a45d384"
                ></ins>
                <!-- Revive Adserver Hosted edition - Ad Management Tag Immagine senza cookie - Generated with Revive Adserver v5.0.5 -->
               
              </div>
            </div>
          </div>
        </div>
        <h1 v-else style="text-align: center; margin: 0 auto">
          <v-progress-circular
            style="margin-top: 20px"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </h1>
        <!-- LE MIE RIVISTE -->
        <div class="section border-red" id="grid__riviste" v-if="logged && riviste">
          <div class="intro">
            <div class="intro">
              <h2>Le Tue Riviste</h2>
              <h4>Tutte le nuove pubblicazioni.</h4>
            </div>
          </div>
          <div>
            <div class="hidden-sm-and-down">
              <!-- GRID box per gestire la visualizzazione a griglia dei volumi -->
              <div class="grid clearfix">
                <div
                  class="grid__item"
                  v-for="item in riviste.es.list"
                  :key="item"
                  @click="detail(item)"
                >
                  <a class="grid__item__caption">
                    <div class="grid__item__caption__image">
                      <v-img
                        v-if="
                          item._source.provvedimento.campiCalcolati.cover_url
                        "
                        width="217"
                        :src="fixCopertine(
                          settings.copertina +
                          '/pdf/' +
                          item._source.provvedimento.idDocMaster
                            .toString()
                            .substring(
                              item._source.provvedimento.idDocMaster.toString()
                                .length - 3,
                              item._source.provvedimento.idDocMaster.toString()
                                .length
                            ) +
                          item._source.provvedimento.campiCalcolati.cover_url +
                          '?width=217&fit=outside')
                        "
                        :aspect-ratio="0.629"
                      ></v-img>

                      <v-img
                        v-else
                        width="217"
                        :src="fixCopertine(item._source.provvedimento.campiCalcolati.rivista_cover_url)"
                      ></v-img>
                    </div>
                    <div class="grid__item__caption__text">
                      <h4>{{ item._source.provvedimento.eTitolo }}</h4>
                      <p>
                        <span v-if="item._source.provvedimento.collana">{{
                          item._source.provvedimento.collana.descrizione
                        }}</span>
                        <br />
                        <span
                          style="font-weight: bold; display: block"
                          v-if="false && item._source.autori"
                          >{{ item._source.autori[0].nome.substring(0, 1) }}.
                          {{ item._source.autori[0].cognome }}
                        </span>
                        <span
                          style="font-weight: bold"
                          v-if="
                            false &&
                            item._source.autori &&
                            item._source.autori[1]
                          "
                          >{{ item._source.autori[1].nome.substring(0, 1) }}.
                          {{ item._source.autori[1].cognome }}</span
                        >
                      </p>
                    </div>
                    <div class="metadata-row" style="margin-left:15px; margin-top:-15px">
                      <span class="metadata">Anno:</span>
                      <span class="metadata__value" style="color:black">{{
                        item._source.provvedimento.annoRiv
                      }}</span>
                    </div>
                    <div class="metadata-row" style="margin-left:15px">
                      <span class="metadata">Fascicolo:</span>
                      <span class="metadata__value" style="color:black"
                        >{{ item._source.provvedimento.fascicolo1 }} 
                        {{ item._source.provvedimento.fascicolo2 ? " - " + item._source.provvedimento.fascicolo2 : "" }}</span
                      >
                    </div>
                  </a>
                </div>
              </div>

              <!-- in versione smartphone invece del div GRID verrà mostrato il div con il carosello immagini -->
              <!--<div class="carousel">
              <div class="corousel__pagination-bullets"></div>
          </div>-->
            </div>

            <v-card
              elevation="24"
              max-width="444"
              class="mx-auto hidden-md-and-up"
              style="width: 217px; !important"
            >
              <v-carousel
                v-model="model2"
                hide-delimiters
                @change="carousel2(item)"
                class="carousel"
              >
                <v-carousel-item
                  v-for="item in riviste.es.list"
                  :key="item"
                  @click="detail(item)"
                  class="corousel__pagination-number"
                >
                  <v-img
                    v-if="item._source.provvedimento.campiCalcolati.cover_url"
                    width="217"
                    :src="fixCopertine(
                      settings.copertina +
                      '/pdf/' +
                      item._source.provvedimento.idDocMaster
                        .toString()
                        .substring(
                          item._source.provvedimento.idDocMaster.toString()
                            .length - 3,
                          item._source.provvedimento.idDocMaster.toString()
                            .length
                        ) +
                      item._source.provvedimento.campiCalcolati.cover_url +
                      '?width=217&fit=outside')
                    "
                    :aspect-ratio="0.629"
                  ></v-img>

                  <v-img
                    v-else
                    width="217"
                    :src="fixCopertine(item._source.provvedimento.campiCalcolati.rivista_cover_url)"
                  ></v-img>
                </v-carousel-item>
              </v-carousel>
            </v-card>
            <v-row
              justify="space-around"
              v-if="currentItemRiviste"
              style="margin-top: 20px"
              class="mx-auto hidden-md-and-up"
            >
              <div class="grid__item__caption__text">
                <h4>{{ currentItemRiviste._source.provvedimento.eTitolo }}</h4>
                <p>
                  <span
                    v-if="currentItemRiviste._source.provvedimento.collana"
                    >{{
                      currentItemRiviste._source.provvedimento.collana
                        .descrizione
                    }}</span
                  >
                  <br />
                  <span
                    style="font-weight: bold"
                    v-if="currentItemRiviste._source.autori && false"
                    >{{
                      currentItemRiviste._source.autori[0].nome.substring(0, 1)
                    }}. {{ currentItemRiviste._source.autori[0].cognome }}
                  </span>
                  <span
                    style="font-weight: bold"
                    v-if="
                      false &&
                      currentItemRiviste._source.autori &&
                      currentItemRiviste._source.autori[1]
                    "
                    >{{
                      currentItemRiviste._source.autori[1].nome.substring(0, 1)
                    }}. {{ currentItemRiviste._source.autori[1].cognome }}</span
                  >
                </p>
              </div>
            </v-row>

             <div class="banner__promo">
                        <div class="row">
                            <div class="col-sm-12 col-12">
                                <!-- Revive Adserver Hosted edition - Ad Management Tag Immagine senza cookie - Generated with Revive Adserver v5.0.5 -->
                                <a class="banner__promo__link" href='https://servedby.revive-adserver.net/ck.php?zoneid=3311' target='_blank'>
                                    <img src='https://servedby.revive-adserver.net/avw.php?zoneid=3311&amp;cb=INSERT_RANDOM_NUMBER_HERE' border='0' alt='' />
                                </a>
                            </div>
                        </div>
                    </div>

          </div>
        </div>
      </div>
    </div>
    <div class="results-list" style="text-align: center" v-else>
      <v-progress-circular
        style="margin-top: 20px"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <!-- PROMO -->
    <div class="section bg-grey">
      <div class="promo container">
        <div class="promo__caption">
          <div class="promo__caption__copy">
            <h3>COMINCIA SUBITO IL TUO PERIODO DI PROVA</h3>
            <a v-if="settings"
              :href="settings.marketing.url_form_agenti"
              class="cta red small"
              target="_blank"
              title="Richiedi maggiori informazioni"
              >Richiedi maggiori informazioni</a
            >
          </div>
        </div>
      </div>
    </div>
    <v-container class="maxlayout">
      <div class="banner__promo">
        <div class="row">
          <div class="col-sm-12 col-12" style="text-align:center">
            <!-- Revive Adserver Hosted edition - Ad Management Asynchronous JS Tag - Generated with Revive Adserver v5.0.5 -->
            <ins type="text/plain" class="optanon-category-C0004"
              data-revive-zoneid="7165"
              data-revive-id="727bec5e09208690b050ccfc6a45d384"
            ></ins>
            <!-- Revive Adserver Hosted edition - Ad Management Tag Immagine senza cookie - Generated with Revive Adserver v5.0.5 -->
            
          </div>

          <div class="col-sm-6 col-12" v-if="logged">
            <!-- Revive Adserver Hosted edition - Ad Management Asynchronous JS Tag - Generated with Revive Adserver v5.0.5 -->
            <ins type="text/plain" class="optanon-category-C0004"
              data-revive-zoneid="7163"
              data-revive-id="727bec5e09208690b050ccfc6a45d384"
            ></ins>
            <!-- Revive Adserver Hosted edition - Ad Management Tag Immagine senza cookie - Generated with Revive Adserver v5.0.5 -->
            
          </div>
          <div class="col-sm-6 col-12" v-if="logged">
            <ins type="text/plain" class="optanon-category-C0004"
              data-revive-zoneid="7164"
              data-revive-id="727bec5e09208690b050ccfc6a45d384"
            ></ins>
            <!-- Revive Adserver Hosted edition - Ad Management Tag Immagine senza cookie - Generated with Revive Adserver v5.0.5 -->
            <a class="banner__promo__link" target="_blank"> 
               
            </a>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<style>
.cover__caption__copy__searchbox div .v-input__slot {
  background: white !important;
}
.v-select-list {
  text-align: left;
}
.v-card {
  transition: opacity 0.3s ease-in-out;
}
.v-menu__content {
  box-shadow: none !important;
}
.v-card:not(.on-hover) {
  opacity: 0.8;
}
.v-text-field__slot input,
.v-input__slot input {
  border: none;
}
.v-menu__content.theme--light.menuable__content__active.v-autocomplete__content {
  /*min-width: 350px !important;
  width: 350px !important;*/
}
i.v-icon.notranslate.mdi.mdi-menu-down.theme--light {
  cursor: pointer !important;
}
.v-image.v-responsive.v-carousel__item.theme--dark,
.v-window.carousel.v-item-group.theme--dark.v-carousel {
  height: auto !important;
}
</style>
<script>
import NoContentViewVue from "../components/catalogue/NoContentView.vue";
import axios from "axios";

export default {
  props: {
    authorization: {
      type: Object,
    },
    overlay: {
      type: Boolean,
    },
    settings: {
      type: Object,
    },
  },
  components: {
    NoContentView: NoContentViewVue,
  },
  data: (vm) => ({
    user: null,
    catalogue: null,
    riviste: null,
    materie: [],
    logged: false,
    materiaSelected: null,
    searchText: null,
    currentItem: null,
    currentItemRiviste: null,
    model: "",
    model2: "",
    more: false,
    ifMateria: false,
    catalogueUnlimited: [],
    catalogueVolumi: [],
    propcombo: { maxHeight: "250", minWidth: "350" },
  }),
  mounted() {

    this.$emit("checkQSTicket", this.$route.query); //va messo in tutte le viste
    localStorage.magazineCall = false;
    console.log("home");

    if (this.authorization.jwt) {
      this.user = this.authorization;
      this.logged = true;
    } else if (this.settings.configuration) {
     this.logged = this.authorization.profile.userId ? this.authorization.profile.userId : null;
    };

    if (this.settings.configuration && !this.settings.goToVolumes) {
      var header = {
        Authorization: localStorage.token_biblio_riv_jwt,
      };

      this.materiaSelected = this.settings.materiaSelected
        ? this.settings.materiaSelected
        : null;

      this.materiaSelected = null;

      if (localStorage.materie) {
        //controllo la data di scadenza per il refresh, settata a 20 min
        if (localStorage.dateSavedMaterie) {
          var now = Date.parse(new Date()) / 1000;
          var exp =
            parseInt(localStorage.dateSavedMaterie) +
            parseInt(this.settings.configuration.timeoutReloadMaterie);
          if (now >= exp) {
            this.loadMaterie(header);
          } else {
            this.materie = JSON.parse(localStorage.materie);
          }
        } else {
          localStorage.materie = [];
          this.loadMaterie(header);
        }
      } else {
        this.loadMaterie(header);
      }

      var bodyList = {
        sort: {
          name: "cronologico",
          order: "desc",
        },
        useCache: this.settings.useCache,
      };
      bodyList.detail = false;
      this.REQUEST(
        this.settings.configuration.apiURL +
          "/ricerca/riviste/" +
          0 +
          "/" +
          8 +
          "?nlp=false&jwt_role=false",
        header,
        "POST",
        "list",
        bodyList
      );

      if(this.logged){
          this.REQUEST(
          this.settings.configuration.apiURL +
            "/ricerca/riviste/" +
            8 +
            "/" +
            8 +
            "?nlp=false&jwt_role=false",
          header,
          "POST",
          "list2",
          bodyList
        );
      }

      this.$gtm.trackEvent({
        target: "home",
        action: "pageview",
        path: this.$route.path,
        crmId:
          this.authorization &&
          this.authorization.profile &&
          this.authorization.profile.crmId
            ? this.authorization.profile.crmId
            : "free",
        roles:
          this.authorization &&
          this.authorization.profile &&
          this.authorization.profile.roless
            ? this.authorization.profile.roless
            : null,
        session: this.createSessionTimestamp(),
      });
    }
  },
  created() {},
  methods: {
    async REQUEST(call, header, method, type, body) {
      var self = this;
      this.loading = true;
      if (method == "POST") {
        await axios
          .post(call, body, { headers: header })
          .then(function (response) {
            switch (type) {
              case "list":
                self.catalogue = response.data;
                self.currentItem = response.data.es.list[0];
                self.$forceUpdate();
                break;
              case "list2":
                self.riviste = response.data;
                self.currentItemRiviste = response.data.es.list[0];
                self.$forceUpdate();
                break;
              case "listUnlimited":
                self.catalogueUnlimited = response.data.list;
                break;
              case "listVolumi":
                self.catalogueVolumi = response.data.list;
                break;
              case "search":
                self.settings.catalogo = {
                  search: response.data,
                  materiaSelected: self.materiaSelected,
                  materie: self.materie,
                  fromDetail: false,
                  fromUnlimited: false,
                  filters: {
                    fromDetail: false,
                    fromUnlimited: false,
                    fromVolumes: false,
                  },
                };
                self.settings.currentRivistaSelectedId = null;
                self.$router.push({
                  path: "results",
                  query: { more: self.more, materia: self.ifMateria },
                });

                break;
            }
          })
          .catch(function (error) {
            //localStorage.token_biblio_riv = null;
            localStorage.token_biblio_riv_jwt = self.settings.free.jwt;
            self.token = self.settings.free.jwt;
            //self.settings.freeToken = true;
          });
      } else {
        await axios
          .get(call, { headers: header })
          .then(function (response) {
            switch (type) {
              case "materie":
                self.materie = response.data;
                self.materie.unshift({
                  descrizione: "Tutto il catalogo".toUpperCase(),
                });
                localStorage.materie = JSON.stringify(self.materie);
                localStorage.dateSavedMaterie = Date.parse(new Date()) / 1000;
                break;
            }
          })
          .catch(function (error) {});
      }
    },
    setMateria() {
      if (this.materiaSelected.descrizione != "TUTTO IL CATALOGO")
        this.research(true);
    },
    createSessionTimestamp() {
      var d = new Date();
      var session =
        d.getFullYear().toString() +
        d.getMonth().toString() +
        d.getDate().toString() +
        d.getHours() +
        d.getMinutes() +
        d.getSeconds();
      return session;
    },
    async loadMaterie(header) {
      await this.REQUEST(
        this.settings.configuration.apiURL + "/materie",
        header,
        "GET",
        "materie",
        null
      );
    },
    moreResults() {
      this.settings.catalogo = null;
      this.$router.push({
        path: "results",
        query: { more: true },
      });
    },
    carousel(item) {
      this.currentItem = this.catalogue
        ? this.catalogue.es.list[this.model]
        : {};
      this.$forceUpdate();
    },
    carousel2(item) {
      this.currentItemRiviste = this.riviste
        ? this.riviste.es.list[this.model2]
        : {};
      this.$forceUpdate();
    },
    keyPress(e) {
      if (e.keyCode === 13) {
        this.research();
      }
    },
    fixCopertine(uri){
        return uri.replace(/([^:]\/)\/+/g, '$1')
      },
    research(setMateria) {

      var from = 0;
      var body = {};
      if (
        (this.searchText != null && this.searchText.length > 0) ||
        setMateria
      ) {
        if (
          this.materiaSelected &&
          this.materiaSelected.descrizione.toUpperCase() != "TUTTO IL CATALOGO"
        ) {
          body = {
            filters: [
              { name: "materia", value: [this.materiaSelected.id.toString()] },
            ],
          };
        } else {
          body = {
            filters: [],
          };
        }
        if (this.searchText) {
          this.more = false;
          this.ifMateria = false;
          body.text = this.searchText;
          body.detail = true;
          //body.sort = { name: "_score", order: "desc" };
          body.sort = { name: "_score", order: "desc" };
        } else {
          body.detail = false;
          this.ifMateria = true;
          this.settings.materiaSelected = this.materiaSelected;
          this.more = true;
          //body.sort = { name: "provvedimento.data", order: "desc" };
          body.sort = { name: "_score", order: "desc" };
          if (setMateria) {
            body.sort = { name: "cronologico", order: "desc" };
          }
        }
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt,
        };
        this.settings.curentFilter = "Tutto il Catalogo";
        this.settings.currentSearchWord = this.searchText;
        //decommenta quando il servizio sarà pronto, ora risultati statici
        
        this.REQUEST(
          this.settings.configuration.apiURL +
            "/ricerca/riviste/0/8?nlp=false&jwt_role=false",
          header,
          "POST",
          "search",
          body
        );
      }
    },
    detail(item) {
      this.settings.homeResults = {
        interesse: item._source.provvedimento.interesse,
      };
      this.$router.push({
        path: "/details",
        query: { id_doc_master: item._source.provvedimento.idDocMaster },
      });
    },
  },
};
</script>
