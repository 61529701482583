var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"app"}},[_c('div',{staticStyle:{"position":"fixed","width":"100%","z-index":"920"},attrs:{"dense":"","light":""}},[_c('v-content',{},[_c('header',[_c('div',{staticClass:"header d-flex align-center",staticStyle:{"margin-top":"48px"},on:{"click":function($event){return _vm.manageBtnHeader('all')}}},[_c('div',{staticClass:"container",staticStyle:{"display":"block"}},[_c('div',{staticClass:"header__logos"},[_c('router-link',{staticClass:"header__logos__site-logo router-link-exact-active router-link-active",attrs:{"to":_vm.routerLogo()}},[_c('img',{attrs:{"src":"images/bibl_riv_new.png","alt":"Logo BIBLIOTECA RIVISTE"}})]),_c('span',[_vm._v("Modulo della banca dati editoriale DeJure")])],1),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobileOn),expression:"mobileOn"}],staticClass:"header__nav",staticStyle:{"margin-top":"25px"}},[_c('a',{staticClass:"close-menu",on:{"click":function($event){_vm.mobileOn = false}}},[_c('span',{staticClass:"icon-close"})]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],class:[
                  { header__nav__item: true },
                  {
                    'header__nav__item--active': _vm.currentRoute('/') || _vm.currentRouteType('home')
                  }
                ],on:{"click":function($event){return _vm.routeCheckMobile()}}},[_c('router-link',{attrs:{"to":'/',"title":"Home"}},[_vm._v("Home")])],1),_c('li',{class:[
                  { header__nav__item: true },
                  {
                    'header__nav__item--active': _vm.currentRoute('/catalogo') || _vm.currentRouteType('catalogo') || _vm.fromCatalogue()
                  }
                ],on:{"click":function($event){return _vm.routeCheckMobile()}}},[_c('router-link',{attrs:{"to":'/catalogo',"title":"Il Catalogo"}},[_vm._v("Il Catalogo")])],1),_c('li',{class:[
                  { header__nav__item: true },
                  {
                    'header__nav__item--active': _vm.currentRoute('/riviste') || _vm.currentRouteType('riviste') || _vm.fromVolumes()
                  }
                ],on:{"click":function($event){return _vm.routeCheckMobile()}}},[_c('router-link',{attrs:{"to":'/riviste',"title":"Le mie riviste"}},[_vm._v("Le mie riviste")])],1)]),_c('div',{staticClass:"header__icon-bar",staticStyle:{"float":"right"}},[_c('a',{staticClass:"header__icon-bar__mobile-nav",on:{"click":function($event){_vm.mobileOn = true}}},[_c('span'),_c('span'),_c('span')]),(_vm.settings && _vm.settings.marketing)?_c('a',{staticClass:"header__icon-bar__private",attrs:{"href":_vm.settings.marketing.url_faq,"target":"_blank","title":"Leggi le FAQ"}},[_c('img',{attrs:{"src":"images/question-solid.svg","alt":"Icona FAQ"}}),_c('span',[_vm._v("FAQ")])]):_vm._e()]),(_vm.settings && _vm.settings.marketing)?_c('a',{staticClass:"header__icon-bar__private fullw",attrs:{"href":_vm.settings.marketing.url_faq,"target":"_blank","title":"Leggi le FAQ"}},[_c('img',{attrs:{"src":"images/question-solid.svg","alt":"Icona FAQ"}}),_c('span',{staticStyle:{"font-family":"sans-serif","font-weight":"400","font-size":"10pt"}},[_vm._v("FAQ")])]):_vm._e()])])])])],1),_c('v-snackbar',{attrs:{"timeout":10000},model:{value:(_vm.showModalError),callback:function ($$v) {_vm.showModalError=$$v},expression:"showModalError"}},[_vm._v(" "+_vm._s(_vm.modalMessage)+" "),_c('v-btn',{attrs:{"color":"error","text":"","icon":""},on:{"click":function($event){_vm.showModalError = false}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("close")])],1)],1),_c('v-overlay',{staticStyle:{"z-index":"999"},attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',{on:{"click":function($event){return _vm.manageBtnHeader('all')}}},[_c('router-view',{key:_vm.token,staticClass:"container__margin",attrs:{"authorization":_vm.authorization,"overlay":_vm.loading,"settings":_vm.settings},on:{"authorize":_vm.authorize,"checkQSTicket":_vm.checkQSTicket}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }