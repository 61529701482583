import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import GQL from '../services/gql'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sso: null,
    config: {},
    jwt: null,
    profile: {},
    rivistaTabSelected: 0
  },
  mutations: {
    SET_CONFIG(state, data) {
      state.config = data
    },
    SET_AUTH(state, data) {
      state.sso = data.sso
      state.jwt = data.jwt
    },
    SET_PROFILE(state, data) {
      state.profile = data
    },
    SET_RIVISTA_TAB(state, data) {
      state.rivistaTabSelected = data
    }
  },
  actions: {
    setConfig({ commit }, payload) {
      commit('SET_CONFIG', payload)
    },
    setAuth({ commit }, payload) {
      commit('SET_AUTH', payload)
    },
    setProfile({ commit }, payload) {
      commit('SET_PROFILE', payload)
    },
    setRivistaTab({ commit }, payload) {
      commit('SET_RIVISTA_TAB', payload)
    },
    async API({ commit, state }, data) {
      const header = {
        Authorization: state.jwt
      }
      let res = null
      if (data.type == 'PUT') {
        res = await axios.put(data.call, data.body, { headers: header })
      }
      if (data.type == 'GET') {
        res = await axios.get(data.call, { headers: header })
      }
      return res
    },
    getDocumentAuth({ state, commit }, payload) {
      const data = {
        idm: payload.idm,
        url: state.config.news.newton,
        jwt: state.config.news.token
      }
      return GQL.getDocumentAuth(data).then((res) => {
        return res ? res.getDocumentAuth[0] : null
      })
    },
    getLinksByHref({ state, commit }, payload) {
      const data = {
        payload: payload,
        url: state.config.news.newton,
        jwt: state.config.news.token
      }
      return GQL.getLinksByHref(data).then((res) => {
        return res || null
      })
    },
    async putDownload({ commit, state }, payload) {
      //const uri = state.config.pdf_build.url.send_request + '/api/v1/queue/' + state.config.pdf_build.queue
      const uri = state.config.configuration.apiURL + '/utility/queue/' + state.config.pdf_build.queue
      const body = {
        call: uri,
        body: payload,
        type: 'PUT'
      }
      return await this.dispatch('API', body)
    }
  },
  getters: {
    downloadRole(state) {
      if (state.profile.roles) {
        return state.profile.roles.find((x) => x == 'pay') ? true : false
      }
      return false
    }
  }
})
